.news {
  border-bottom: 3px solid $lt-blue;
  border-top: 3px solid $lt-blue;
  color: $white; 
  text-align: center; 
  
  > div {
    background-color: $lt-blue; 
    background-image: img-url("bg-texture.png");
    border-bottom: 5px solid $white;
    border-top: 5px solid $white;
    padding: 0 $gridpad/4 $gridpad;
    
    @include breakpoint($medium) {
      padding: 0 $gridpad/2 $gridpad;
    }
    
    @include breakpoint($large) {
      padding: 0 $gridpad $gridpad;
    }
  }
  
  p a {
    color: $yellow;
  }

  h1 {
    background-color: $white; 
    border-radius: 0 0 0.25em 0.25em;
    color: $lt-blue;
    font-size: 2.25em;
    margin: 0 auto;
    padding: 0.25em;
    text-align: center; 
    width: 33%;
  }

  h2 {
    color: $white; 
    font-size: 2em;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint($medium) {
      font-size: 2.5em;
      max-width: 750px;
    }
  }
}
