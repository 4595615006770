.jumbotron {
  background-color: $yellow; 
  background-image: img-url("bg-texture.png");
  border-top: 15px solid $white;
  margin: -1em (-1 * $gridpad/4);
  padding: $gridpad/2;
  text-align: center;
  
  @include breakpoint($medium) {
    @include border-radius(10px);
    border-top: 0;
  }

  @include breakpoint($large) {
    @include border-radius(10px);
    margin: -1em -3em 0;
    padding: ($vertpad * 2) ($gridpad * 2); 
  }

  h1,
  h2,
  h3,
  h4 { 
    color: $white; 
    margin: 0;
  }
  
  h1 {
    font-size: 4em;
    line-height: 1;
    
    @include breakpoint($large) {
      font-size: 6em;
    }
  }

  h1::before {
    content: img-url("svg/location-icon.svg");
    display: block;
    height: 100px;
    margin: 0 auto;
    padding-bottom: 0.5em;
    width: 100px;
  }

  p {
    color: lighten($black, 15%);
    font-size: 1.45em;
    line-height: 1.5;
    margin: 1em auto;
    max-width: 700px;
  }
}
