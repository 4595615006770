.site-header {
  @include breakpoint($medium) {
    padding: 0 $gridpad/4;
  }

  h1 {
    margin: 0; 
  }
}

