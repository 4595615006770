.meta {
  background: rgba($white, 0.48);
  
  img {
    max-width: 321px;
    padding: 1em 0 0.5em $gridpad/4;
    width: 48%;
    
    @include breakpoint($medium) {
      padding: 1em 0 0.5em $gridpad/2;
    }
  }
}
