$pagewidth: 63em;
$gridpad: 4em;
$vertpad: 1em; 

* {
  box-sizing: border-box;
}

.contained {
  margin: 0 auto;
  max-width: $pagewidth;
}

