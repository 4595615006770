.section-nav {
  @include clearfix;
  background-position: top left;
  background-repeat: no-repeat;
  font-family: "franchiseregular", "Helvetica Nueue", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  
  @include breakpoint($large) {
    background-image: img-url("yellow-arrow.png");
  }
  
  h1 {
    font-size: 3em;
    line-height: 0.9;
    margin: 1em auto 0.5em;
    
    @include breakpoint($small) {
      font-size: 3em;
    }
    
    @include breakpoint($medium) {
      max-width: 700px;
    }
    
    @include breakpoint($large) {
      font-size: 4em;
      line-height: 0.9;
      margin: 1em 0.5em 0.5em 2.5em;
      max-width: none;
    }
  }

  a { 
    @include transition(all, 0.3s);
    display: block;
    font-size: 1.3em;
    line-height: 60px;
    text-decoration: none;
    
    @include breakpoint($small) {
      font-size: 1.6em;
    }
    
    @include breakpoint($medium) {
      display: inline-block;
      line-height: 1;
      min-width: 220px;
      padding: 1em;
      width: 29%;

      &:not(:last-child) {
        border-right: 2px dotted $yellow;
      }
    }
    
    @include breakpoint($large) {
      min-width: 205px;
      width: 19%;
    }
    
    &:hover {
      opacity: 0.8;
    }
  }

  a::before {
    $iconsize: 36px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: $iconsize;
    margin-right: 1em;
    position: relative;
    top: -5px;
    vertical-align: middle;
    width: $iconsize;
    
    @include breakpoint($medium) {
      $iconsize: 100px;
      display: block;
      height: $iconsize;
      margin: 0 auto 1em;
      width: $iconsize;
    }
  }
  
  .workforce {
    color: $orange;
    
    &::before {
      background-image: img-url("svg/workforce-icon.svg");
    }
  }

  .pedestrian {
    color: $red;

    &::before {
      background-image: img-url("svg/pedestrian-icon.svg");
    }
  }

  .residential {
    color: $teal;

    &::before {
      background-image: img-url("svg/residential-icon.svg");
    }
  }

  .visitor {
    color: $green;

    &::before {
      background-image: img-url("svg/visitor-icon.svg");
    }
  }

  .parking {
    color: $yellow;

    &::before {
      background-image: img-url("svg/parking-icon.svg");
    }
  }

}

