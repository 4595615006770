/*--------------------------------------------------
	
	# sass / abstractions / _mixins.scss

	Common mixins, including a couple of troubleshooting helpers.
	

	--------------------------------------------------*/
/*----------------------------------------------------

	A couple of useful mixins sourced from:
	http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/

----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*---------------------------------------------------------*/
/*---------------------------------------------------------*/
* {
  box-sizing: border-box; }

.contained {
  margin: 0 auto;
  max-width: 63em; }

body {
  margin: 0;
  padding: 0;
  text-align: left; }

a {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: inherit; }
  a:hover {
    text-decoration: none; }

p a {
  color: #088bd4; }
  p a:hover {
    color: #11a0dc; }

img {
  max-width: 100%; }

img[style*="float:right"],
img[style*="float: right"] {
  margin: 0 0 1em 1em; }

img[style*="float:left"],
img[style*="float: left"] {
  margin: 0 1em 1em 0; }

@media (max-width: 43.75em) {
  img[style*="float"] {
    display: block;
    float: initial !important;
    margin: 1em auto; } }
iframe {
  outline: none;
  border: none; }

a img {
  border: 0; }

table.rwd-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }
  @media (max-width: 30em) {
    table.rwd-table td {
      display: block; }
      table.rwd-table td.subhead {
        display: none; } }
  table.rwd-table td:first-child {
    padding-top: .5em; }
  table.rwd-table td:last-child {
    padding-bottom: .5em; }
  table.rwd-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 100%;
    display: inline;
    color: #11a0dc; }
    @media (min-width: 30em) {
      table.rwd-table td:before {
        display: none; } }
  table.rwd-table th {
    background: #11a0dc; }
    table.rwd-table th h3 {
      color: white;
      font-family: "Muli",serif;
      font-weight: normal;
      font-size: 1em; }
  table.rwd-table td:before,
  table.rwd-table th:before {
    color: #11a0dc; }
  @media (max-width: 50.5em) {
    table.rwd-table td,
    table.rwd-table th {
      font-size: 0.8em;
      line-height: 1.3;
      padding: 0.2em;
      text-align: left;
      vertical-align: top; } }
  @media (min-width: 50.5em) {
    table.rwd-table td,
    table.rwd-table th {
      border: 1px solid #bab6b6;
      padding: 0.5em;
      text-align: center; } }
  @media (min-width: 30em) {
    table.rwd-table td,
    table.rwd-table th {
      display: table-cell;
      padding: 0.5em 0; }
      table.rwd-table td:first-child,
      table.rwd-table th:first-child {
        padding-left: 0; }
      table.rwd-table td:last-child,
      table.rwd-table th:last-child {
        padding-right: 0; } }
  table.rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    @media (max-width: 50.5em) {
      table.rwd-table tr.empty {
        display: none !important; } }
  table.rwd-table .subhead {
    background: #d7f1fc; }
  table.rwd-table .footer {
    padding-bottom: 2em; }
  table.rwd-table p {
    margin: 0;
    display: inline; }

body .legend {
  text-align: right;
  font-size: 0.8em;
  font-style: italic; }

/*
@font-face {
  font-family: "Invest";
  src: url("webfont.eot"); /* IE9 Compat Modes *
  src: url("webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 
       url("webfont.woff2") format("woff2"), /* Super Modern Browsers *
       url("webfont.woff") format("woff"), /* Pretty Modern Browsers *
       url("webfont.ttf")  format("truetype"), /* Safari, Android, iOS *
       url("webfont.svg#svgFontName") format("svg"); /* Legacy iOS *

*/
@font-face {
  font-family: "Franchise Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Franchise Bold"), url("../fonts/Franchise-Bold-hinted.woff") format("woff"); }
@font-face {
  font-family: "franchiseregular";
  src: url("../fonts/franchise/franchise-bold-webfont.eot");
  src: url("../fonts/franchise/franchise-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/franchise/franchise-bold-webfont.woff2") format("woff2"), url("../fonts/franchise/franchise-bold-webfont.woff") format("woff"), url("../fonts/franchise/franchise-bold-webfont.ttf") format("truetype"), url("../fonts/franchise/franchise-bold-webfont.svg#franchiseregular") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/icomoon.eot"); }
@font-face {
  font-family: "icomoon";
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SAXQAAAC8AAAAYGNtYXAaVcyJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5ZvNmGygAAAF4AAAAmGhlYWQE1kF1AAACEAAAADZoaGVhBigDxgAAAkgAAAAkaG10eAiSACwAAAJsAAAAFGxvY2EAKABgAAACgAAAAAxtYXhwAAcAHQAAAowAAAAgbmFtZZlKCfsAAAKsAAABhnBvc3QAAwAAAAAENAAAACAAAwJJAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADmAQPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg5gH//f//AAAAAAAg5gH//f//AAH/4xoDAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABACwA9QJmAkIAGgAAARQHAQYjIicBJjU0PwE2MzIfATc2MzIfARYVAmYF/vUFCAcG/vYGBhwGBwgG4OEFCAcGHQUCEgcG/vYGBgEKBgcIBR0GBuHhBgYdBQgAAAEAAAABAACqgax/Xw889QALBAAAAAAA0aj/GwAAAADRqP8bAAAAAAJmAkIAAAAIAAIAAAAAAAAAAQAAA8D/wAAABAAAAAAAAmYAAQAAAAAAAAAAAAAAAAAAAAUEAAAAAAAAAAAAAAACAAAAApIALAAAAAAACgAUAB4ATAABAAAABQAbAAEAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEABwAAAAEAAAAAAAIABwBgAAEAAAAAAAMABwA2AAEAAAAAAAQABwB1AAEAAAAAAAUACwAVAAEAAAAAAAYABwBLAAEAAAAAAAoAGgCKAAMAAQQJAAEADgAHAAMAAQQJAAIADgBnAAMAAQQJAAMADgA9AAMAAQQJAAQADgB8AAMAAQQJAAUAFgAgAAMAAQQJAAYADgBSAAMAAQQJAAoANACkaWNvbW9vbgBpAGMAbwBtAG8AbwBuVmVyc2lvbiAxLjAAVgBlAHIAcwBpAG8AbgAgADEALgAwaWNvbW9vbgBpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuUmVndWxhcgBSAGUAZwB1AGwAYQByaWNvbW9vbgBpAGMAbwBtAG8AbwBuRm9udCBnZW5lcmF0ZWQgYnkgSWNvTW9vbi4ARgBvAG4AdAAgAGcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAuAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==) format("truetype");
  font-weight: normal;
  font-style: normal; }
body {
  color: #545050;
  font-family: "Muli", sans-serif;
  font-size: 1.1em;
  line-height: 1.7; }
  @media (min-width: 50.5em) {
    body {
      font-size: 1.25em; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #11a0dc;
  font-family: "franchiseregular";
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.3; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    text-decoration: none; }
    h1 a:hover,
    h2 a:hover,
    h3 a:hover,
    h4 a:hover,
    h5 a:hover,
    h6 a:hover {
      text-decoration: underline; }

h1 {
  font-size: 3.5rem;
  line-height: 1; }
  @media (min-width: 50.5em) {
    h1 {
      font-size: 4.8rem; } }

h2 {
  font-size: 2.8rem;
  line-height: 1; }

h3,
h4,
h5,
h6 {
  font-size: 1.8rem; }

blockquote {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #ddf2fe;
  margin: 0;
  padding: 2em;
  font-size: 1.2em; }

.needs-review {
  background-color: rgba(255, 203, 8, 0.7);
  font-style: italic; }

.news {
  border-bottom: 3px solid #11a0dc;
  border-top: 3px solid #11a0dc;
  color: white;
  text-align: center; }
  .news > div {
    background-color: #11a0dc;
    background-image: url("../img/bg-texture.png");
    border-bottom: 5px solid white;
    border-top: 5px solid white;
    padding: 0 1em 4em; }
    @media (min-width: 50.5em) {
      .news > div {
        padding: 0 2em 4em; } }
    @media (min-width: 78.75em) {
      .news > div {
        padding: 0 4em 4em; } }
  .news p a {
    color: #ffcb08; }
  .news h1 {
    background-color: white;
    border-radius: 0 0 0.25em 0.25em;
    color: #11a0dc;
    font-size: 2.25em;
    margin: 0 auto;
    padding: 0.25em;
    text-align: center;
    width: 33%; }
  .news h2 {
    color: white;
    font-size: 2em;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 50.5em) {
      .news h2 {
        font-size: 2.5em;
        max-width: 750px; } }

.post-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left; }
  .post-list h2 {
    text-align: left; }
    .post-list h2 a {
      text-decoration: none; }

.button {
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #ffcb08;
  color: white;
  font-family: montserrat, "Helvetica Nueue", sans-serif;
  font-size: 0.8em;
  padding: 1em 4em;
  text-decoration: none;
  text-transform: uppercase; }
  .button:hover {
    background: #f7941f; }

.section-nav {
  overflow: hidden;
  *zoom: 1;
  background-position: top left;
  background-repeat: no-repeat;
  font-family: "franchiseregular", "Helvetica Nueue", sans-serif;
  line-height: 1;
  text-transform: uppercase; }
  @media (min-width: 78.75em) {
    .section-nav {
      background-image: url("../img/yellow-arrow.png"); } }
  .section-nav h1 {
    font-size: 3em;
    line-height: 0.9;
    margin: 1em auto 0.5em; }
    @media (min-width: 30em) {
      .section-nav h1 {
        font-size: 3em; } }
    @media (min-width: 50.5em) {
      .section-nav h1 {
        max-width: 700px; } }
    @media (min-width: 78.75em) {
      .section-nav h1 {
        font-size: 4em;
        line-height: 0.9;
        margin: 1em 0.5em 0.5em 2.5em;
        max-width: none; } }
  .section-nav a {
    -moz-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
    display: block;
    font-size: 1.3em;
    line-height: 60px;
    text-decoration: none; }
    @media (min-width: 30em) {
      .section-nav a {
        font-size: 1.6em; } }
    @media (min-width: 50.5em) {
      .section-nav a {
        display: inline-block;
        line-height: 1;
        min-width: 220px;
        padding: 1em;
        width: 29%; }
        .section-nav a:not(:last-child) {
          border-right: 2px dotted #ffcb08; } }
    @media (min-width: 78.75em) {
      .section-nav a {
        min-width: 205px;
        width: 19%; } }
    .section-nav a:hover {
      opacity: 0.8; }
  .section-nav a::before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 36px;
    margin-right: 1em;
    position: relative;
    top: -5px;
    vertical-align: middle;
    width: 36px; }
    @media (min-width: 50.5em) {
      .section-nav a::before {
        display: block;
        height: 100px;
        margin: 0 auto 1em;
        width: 100px; } }
  .section-nav .workforce {
    color: #f7941f; }
    .section-nav .workforce::before {
      background-image: url("../img/svg/workforce-icon.svg"); }
  .section-nav .pedestrian {
    color: #e51d27; }
    .section-nav .pedestrian::before {
      background-image: url("../img/svg/pedestrian-icon.svg"); }
  .section-nav .residential {
    color: #00b0bd; }
    .section-nav .residential::before {
      background-image: url("../img/svg/residential-icon.svg"); }
  .section-nav .visitor {
    color: #a3d063; }
    .section-nav .visitor::before {
      background-image: url("../img/svg/visitor-icon.svg"); }
  .section-nav .parking {
    color: #ffcb08; }
    .section-nav .parking::before {
      background-image: url("../img/svg/parking-icon.svg"); }

/*! Flickity v1.0.2
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  padding: 0; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 25%;
  height: 0.5em;
  margin: 0 8px;
  background: #ffcb08;
  cursor: pointer;
  max-width: 75px; }

.flickity-page-dots .dot.is-selected {
  background: white; }

/*---------------------------------------------------------*/
.news-post {
  width: 100%; }

.flickity-enabled {
  overflow: hidden;
  *zoom: 1; }

.jumbotron {
  background-color: #ffcb08;
  background-image: url("../img/bg-texture.png");
  border-top: 15px solid white;
  margin: -1em -1em;
  padding: 2em;
  text-align: center; }
  @media (min-width: 50.5em) {
    .jumbotron {
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      border-top: 0; } }
  @media (min-width: 78.75em) {
    .jumbotron {
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      margin: -1em -3em 0;
      padding: 2em 8em; } }
  .jumbotron h1,
  .jumbotron h2,
  .jumbotron h3,
  .jumbotron h4 {
    color: white;
    margin: 0; }
  .jumbotron h1 {
    font-size: 4em;
    line-height: 1; }
    @media (min-width: 78.75em) {
      .jumbotron h1 {
        font-size: 6em; } }
  .jumbotron h1::before {
    content: url("../img/svg/location-icon.svg");
    display: block;
    height: 100px;
    margin: 0 auto;
    padding-bottom: 0.5em;
    width: 100px; }
  .jumbotron p {
    color: #7b7575;
    font-size: 1.45em;
    line-height: 1.5;
    margin: 1em auto;
    max-width: 700px; }

.meta {
  background: rgba(255, 255, 255, 0.48); }
  .meta img {
    max-width: 321px;
    padding: 1em 0 0.5em 1em;
    width: 48%; }
    @media (min-width: 50.5em) {
      .meta img {
        padding: 1em 0 0.5em 2em; } }

body {
  background-image: url("../img/city-bg-sm.jpg");
  background-position: center top;
  background-repeat: no-repeat; }
  @media (min-width: 50.5em) {
    body {
      background-size: contain; } }
  @media (min-width: 62.5em) {
    body {
      background-image: url("../img/city-bg-med.jpg"); } }
  @media (min-width: 100em) {
    body {
      background-image: url("../img/city-bg-large.jpg"); } }

footer {
  text-align: center; }
  footer div:nth-child(2) {
    border-top: 3px solid #ffcb08; }
    @media (min-width: 30em) {
      footer div:nth-child(2) {
        text-align: left; } }
  footer .logo-downtownvic {
    display: block;
    margin: 0 auto; }
    @media (min-width: 30em) {
      footer .logo-downtownvic {
        float: right; } }
  footer .first .contained {
    padding: 2em; }
    @media (min-width: 78.75em) {
      footer .first .contained {
        padding: 5.5em 0 0;
        background-image: url("../img/blue-arrow.png");
        background-repeat: no-repeat;
        background-position: right top; } }
  @media (min-width: 50.5em) {
    footer .first p {
      font-size: 1.3em;
      font-weight: 100; } }
  @media (min-width: 78.75em) {
    footer .first p {
      font-size: 1.5em;
      margin: auto auto 1em;
      max-width: 860px; } }
  footer .first h2 {
    color: #ffcb08; }
    @media (min-width: 78.75em) {
      footer .first h2 {
        border-left: 2px solid #ffcb08;
        display: inline-block;
        line-height: 2;
        margin-left: 1em;
        padding-left: 1em;
        position: relative;
        top: 10px; } }
  footer .first a {
    display: inline-block; }
    footer .first a:hover {
      color: #f7941f; }
    footer .first a.button:hover {
      color: white; }
  @media (min-width: 78.75em) {
    footer h1 {
      margin-top: 0; } }

.closing-credits p {
  margin: 0;
  padding: 2em;
  color: #888282;
  font-size: 0.9em; }
.closing-credits a {
  color: #ffcb08; }
  .closing-credits a:hover {
    color: #f7941f; }
.closing-credits img {
  margin-left: 2em; }

@media (min-width: 50.5em) {
  .site-header {
    padding: 0 1em; } }
.site-header h1 {
  margin: 0; }

.logo-invest {
  display: block;
  margin: -20% auto 0.3em;
  max-width: 100%; }
  @media (min-width: 30em) {
    .logo-invest {
      margin: -90px auto 0.3em; } }

main {
  background-image: url("../img/bg-cityscape.jpg");
  background-position: center bottom;
  background-repeat: repeat-x;
  padding-bottom: 245px; }
  @media (min-width: 50.5em) {
    main {
      padding: 0 1em 245px; } }
  @media (min-width: 78.75em) {
    main {
      padding: 0; } }
  main .contained {
    background: white;
    padding: 1em 1em; }
    @media (min-width: 50.5em) {
      main .contained {
        padding: 1em 2em; } }
    @media (min-width: 78.75em) {
      main .contained {
        padding: 1em 4em; } }

/*=========================================================*
 The Pedestrian Counter Page
 
 We set up a display with dots across a map. Took a little more styling
 than normal to get this one set up...
 *--------------------------------------------------------*/
.pedestrian-count-map {
  background-image: url("../img/dvba-pedestrian-map.gif");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  height: 0;
  margin: 2em auto 0;
  padding-bottom: 129%;
  position: relative;
  width: 100%;
  -webkit-print-color-adjust: exact; }
  .pedestrian-count-map a {
    border: 2px dashed white;
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-size: 0.7em;
    height: 1.5em;
    position: absolute;
    text-decoration: none;
    width: 1.5em;
    line-height: 1.2; }
    @media (min-width: 50.5em) {
      .pedestrian-count-map a {
        font-size: 0.8em;
        width: 2em;
        height: 2em;
        line-height: 1.9; } }
    @media (min-width: 78.75em) {
      .pedestrian-count-map a {
        font-size: 0.9em;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.3; } }
    .pedestrian-count-map a:hover {
      color: #088bd4;
      background: white; }

@media print {
  .pedestrian-count-map a {
    display: none; } }
.yates700 {
  left: 78%;
  top: 46.5%; }

.fort700 {
  left: 78%;
  top: 53.5%; }

.douglas1300 {
  left: 70%;
  top: 40%; }

.johnson600 {
  left: 52%;
  top: 32.5%; }

.blanchard1100 {
  left: 83.2%;
  top: 54%; }

.govt1100 {
  left: 53.7%;
  top: 49%; }

.govt900 {
  left: 55.5%;
  top: 59.5%; }

.douglas1100 {
  left: 67.4%;
  top: 55.5%; }

/*---------------------------------------------------------*/
#primary-nav {
  -moz-border-radius: 0.5em 0.5em 0 0;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em 0.5em 0 0;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  /*---------------------------------------------------------*/ }
  @media (max-width: 50.5em) {
    #primary-nav.activated .menu {
      display: block; } }
  #primary-nav .menu-icon {
    color: white;
    display: block;
    height: 100px;
    line-height: 100px;
    position: absolute;
    right: 1em;
    top: -20px;
    width: 45px; }
    #primary-nav .menu-icon path:hover {
      color: #ffcb08; }
  #primary-nav h1 {
    display: none; }
  #primary-nav ul {
    margin: 0;
    padding: 0; }
  #primary-nav li {
    cursor: pointer;
    list-style: none;
    position: relative;
    padding: 0.5em; }
    @media (min-width: 50.5em) {
      #primary-nav li {
        padding: 1.5em 0.5em; } }
  #primary-nav li,
  #primary-nav a {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #888282; }
    #primary-nav li:hover,
    #primary-nav a:hover {
      color: #11a0dc; }
  #primary-nav a {
    padding: 0.5em;
    text-align: center;
    text-decoration: none; }
  #primary-nav .menu {
    background: white;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 3.5em;
    z-index: 1;
    padding: 1em 0; }
    @media (min-width: 28.125em) {
      #primary-nav .menu {
        top: 4em; } }
    @media (min-width: 37.5em) {
      #primary-nav .menu {
        top: 4.5em; } }
    @media (min-width: 42.1875em) {
      #primary-nav .menu {
        top: 5em; } }
    @media (min-width: 50.5em) {
      #primary-nav .menu {
        background: none;
        padding: 0; } }
    #primary-nav .menu ul {
      display: none; }
      #primary-nav .menu ul a {
        border-bottom: 2px dotted #ffcb08;
        display: block;
        padding: 0 0 1em; }
      #primary-nav .menu ul li {
        padding: 1em 1em 0;
        text-transform: none; }
      #primary-nav .menu ul li:last-child a {
        border-bottom: 0; }
  #primary-nav .icon-down {
    -moz-transition: -moz-transform, 0.3s;
    -o-transition: -o-transform, 0.3s;
    -webkit-transition: -webkit-transform, 0.3s;
    transition: transform, 0.3s;
    display: inline-block;
    font-family: "icomoon";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    margin: 0 0 0 0.5em;
    speak: none;
    text-transform: none; }
  #primary-nav .icon-downchevron-small-down:before {
    content: "\e601"; }
  #primary-nav .activated ul,
  #primary-nav .activated li {
    display: block;
    position: relative; }
  #primary-nav li:first-child {
    -moz-border-radius: 0.5em 0 0 0;
    -webkit-border-radius: 0.5em;
    border-radius: 0.5em 0 0 0; }
  #primary-nav li:last-child {
    -moz-border-radius: 0 0.5em 0 0;
    -webkit-border-radius: 0;
    border-radius: 0 0.5em 0 0; }
  @media (min-width: 50.5em) {
    #primary-nav .menu {
      display: flex;
      position: relative;
      top: 0; }
      #primary-nav .menu ul {
        -moz-border-radius: 0 0 0.5em 0.5em;
        -webkit-border-radius: 0;
        border-radius: 0 0 0.5em 0.5em;
        -moz-box-shadow: 0 10px 13px rgba(84, 80, 80, 0.3);
        -webkit-box-shadow: 0 10px 13px rgba(84, 80, 80, 0.3);
        box-shadow: 0 10px 13px rgba(84, 80, 80, 0.3);
        background: white;
        left: 0;
        min-width: 13em;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 100%; }
      #primary-nav .menu > li {
        display: inline-block;
        flex: 1 1 auto; }
        #primary-nav .menu > li:not(:last-child) {
          border-right: 2px solid #ffcb08; }
      #primary-nav .menu .activated {
        background: #ffcb08;
        color: white; }
        #primary-nav .menu .activated .icon-down {
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
    #primary-nav .menu-icon {
      display: none; } }


