/*=========================================================*
 The Pedestrian Counter Page
 
 We set up a display with dots across a map. Took a little more styling
 than normal to get this one set up...
 *--------------------------------------------------------*/

.pedestrian-count-map {
  background: {
    image: img-url("dvba-pedestrian-map.gif");
    position: center;
    size: contain;
    repeat: no-repeat;
  }
  display: block;
  height: 0;
  margin: 2em auto 0;
  padding-bottom: 129%;
  position: relative;
  width: 100%;
  -webkit-print-color-adjust: exact;
  
  a {
    border: 2px dashed $white;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-size: 0.7em;
    height: 1.5em;
    position: absolute;
    text-decoration: none;
    width: 1.5em;
    line-height: 1.2;
    @include breakpoint($medium) {
      font-size: 0.8em;
      width: 2em;
      height: 2em;
      line-height: 1.9;
    }
    @include breakpoint($large) {
      font-size: 0.9em;
      width: 2.5em;
      height: 2.5em;
      line-height: 2.3;
    }
    &:hover {
      color: $blue;
      background: $white;
    }
  }
}

@media print {
  .pedestrian-count-map a {
    display: none;
  }
}

// Position each of the dots over the pedestrian map:
.yates700 {
  left: 78%;
  top: 46.5%;
}

.fort700 {
  left: 78%;
  top: 53.5%;
}

.douglas1300 {
  left: 70%;
  top: 40%;
}

.johnson600 {
  left: 52%;
  top: 32.5%;
}

.blanchard1100 {
  left: 83.2%;
  top: 54%;
}

.govt1100 {
  left: 53.7%;
  top: 49%;
}

.govt900 {
  left: 55.5%;
  top: 59.5%;
}

.douglas1100 {
  left: 67.4%;
  top: 55.5%;
}
