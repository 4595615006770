$white: rgb(255, 255, 255);
$black: rgb(84, 80, 80);

$orange: rgb(247, 148, 31);
$yellow: rgb(255, 203, 8);
$purple: rgb(180, 128, 164);
$blue: rgb(8, 139, 212);
$red: rgb(229, 29, 39);
$green: rgb(163, 208, 99);
$teal: #00b0bd;

$lt-blue: rgb(17, 160, 220);
