main {
  background-image: img-url("bg-cityscape.jpg");
  background-position: center bottom;
  background-repeat: repeat-x;
  padding-bottom: 245px; 
  //text-align: center;

  @include breakpoint($medium) {
    padding: 0 $gridpad/4 245px;
  }

  @include breakpoint($large) {
    padding: 0;
  }
  
  .contained {
    background: $white; 
    padding: $vertpad $gridpad/4;

    @include breakpoint($medium) {
      padding: $vertpad $gridpad/2;
    }

    @include breakpoint($large) {
      padding: $vertpad $gridpad;
    }
  }

}

