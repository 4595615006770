table.rwd-table { 
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; 
  
  td {
    @include breakpoint(max-width $small) {
      display: block; 
      &.subhead {
        display: none;
      }
    }
    
    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
      font-weight: bold;
      width: 100%;
      display: inline;
      color: $lt-blue;
      
      @include breakpoint(min-width $small) { 
        display: none;
      }
    }
  }
  
  th {
    background: $lt-blue;
    
    h3 {
      color: $white;
      font-family: "Muli",serif;
      font-weight: normal;
      font-size: 1em;
    }
  }
  
  td,
  th {
    &:before {
      color: $lt-blue;
    }
    
    @include breakpoint(max-width $medium) { 
      font-size: 0.8em;
      line-height: 1.3;
      padding: 0.2em;
      text-align: left;
      vertical-align: top;
    }
    
    @include breakpoint(min-width $medium) { 
      border: 1px solid lighten($black,40%);
      padding: 0.5em;
      text-align: center;
    }
    
    @include breakpoint(min-width $small) {
      display: table-cell;
      padding: 0.5em 0;
      
      &:first-child {
        padding-left: 0;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }
  }
    
  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    &.empty {
      @include breakpoint(max-width $medium) { 
        display: none !important;
      }
    }
  }
  
  .subhead {
    background: lighten($lt-blue,45%);
  }
  
  .footer {
    padding-bottom: 2em;
  }
  
  p {
    margin: 0;
    display: inline;
  }
}

body .legend {
  text-align: right;
  font-size: 0.8em;
  font-style: italic;
}