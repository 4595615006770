.post-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;

  h2 { text-align: left; 

    a { text-decoration: none; }
  }

}
